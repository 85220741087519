const config = {
    isProduction: process.env.NODE_ENV === "production",
    isDevelopment: process.env.NODE_ENV !== "production",
    title: process.env.REACT_APP_TITLE,
    apiBase: process.env.REACT_APP_BACKEND_API_BASE,
    authRedirect: '/login',
    authSuccessRedirect: '/',
    apiEndpoints: {
        login: '/login',
        otp: '/login/validate',
        profile: '/me',
        editProfile: '/profile/update',
        createJob: '/job/create',
        createUser: '/user/create',
        createDial: '/dial/create',
    },
}

export default config;