import config from "../../config";
import {Helmet} from "react-helmet-async";
import React from "react";
import UserListComponent from "../../components/UserList";

const UserList = () => {
    return (
        <>
            <Helmet>
                <title>{config.title} - List Users</title>
            </Helmet>
            <UserListComponent/>
        </>
    );
};

export default UserList;