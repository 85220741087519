import {useEffect, useState} from "react";
import {useAuthenticatedAxios} from "../../hooks/useAxios";
import {get as _get} from "lodash"
import {Alert, Badge, Button, Card, Col, Row, Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./device-count.scss";

type DevicesCountCollection = {
    total: number,
    code: string,
    name: string,
    sku: string,
    image_url: string,
}


const DeviceCount = () => {
    const [devices, setDevices] = useState<DevicesCountCollection[]>([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const axios = useAuthenticatedAxios()

    useEffect(() =>  {
        const getDevices = async () => {
            setLoading(true)
            try {
                const response = await axios.get("/product/device/counts");
                if(_get(response, "data.success", false)) {
                    setDevices(_get(response, "data.data", []))
                }
            } catch (e) {
                
            }
            setLoading(false)
        };
        getDevices()
    }, [refresh])

    const handleRefresh = () => {
        setRefresh(refresh+1);
    };

    return (
        <div className="product-device-count mt-2">
            <Card>
                <Card.Body>
                    <Card.Title>Registered Device Counts</Card.Title>
                    <Card.Text>
                        Registered devices per product.
                        <Button className={"float-end"} disabled={loading} size={"sm"} onClick={handleRefresh} variant={"secondary"}>
                            {loading && <Spinner size={"sm"}></Spinner>} REFRESH
                        </Button>
                    </Card.Text>
                    <Card.Body>
                        <Row>
                            <Col>
                                {devices.map(device => (
                                    <div className="pl-widget" key={device.code}>
                                        <div className="img">
                                            {device.image_url && <img src={device.image_url}  alt={device.code}/>}
                                        </div>
                                        <div className="content">
                                            <strong>{device.name}</strong><br />({device.sku})<br />
                                            <strong className="count">{device.total}</strong> users
                                        </div>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card.Body>
            </Card>
        </div>
    )
};

export default DeviceCount;

