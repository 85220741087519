import config from "../../config";
import AsyncSelect from 'react-select/async';
import {Helmet} from "react-helmet-async";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useAuthenticatedAxios} from "../../hooks/useAxios";
import {get as _get, pick as _pick, values} from "lodash";
import {useFormik} from "formik";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import * as Yup from "yup";
import {toast, ToastContainer} from "react-toastify";

const UserSchema = Yup.object().shape({
    first_name: Yup.string().required('Firstname is required'),
    last_name: Yup.string().required('Lastname is required'),
    role: Yup.string().required('Role is required'),
    // products: Yup.array().of(Yup.string().optional())
});

interface ProductOptionsLabelValueInterface {
    label: string;
    value: string;
}

const UserEdit = () => {
    let { id } = useParams();
    const axios = useAuthenticatedAxios()
    const [roles, setRoles] = useState(["Admin", "Super Distributor", "Master Distributor"]);
    const [productOptions, setProductOptions] = useState<Array<ProductOptionsLabelValueInterface>>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const form = useFormik({
        initialValues: {
            role: "",
            distributor_name: "",
            first_name: "",
            last_name: "",
            // products: []
            // is_active: true,
            // is_verified: true
        },
        validationSchema: UserSchema,
        onSubmit: async(values, formikHelpers) => {
            setIsSubmitting(true);
            try {
                const response = await axios.post(`/user/edit/${id}`, values);
                if(response.data.success) {
                    toast(response.data.message);
                }
            } catch (e) {
                toast("Error Occurred");
            }
            setIsSubmitting(false);
        },
    });


    const getConfig = async () => {
        const configResponse = await axios.get("/users/config");
        if(_get(configResponse, "data.success", false)) {
            let roles = _get(configResponse, "data.data.roles", [])
            setRoles(roles)
        }
    };

    const filterProductOptions = async (inputValue: string): Promise<Array<Record<string, string>>> => {
        try {
            const productOptionsResponse = await axios.get("/product/type-ahead", {
                params: {q: inputValue}
            });
            if(_get(productOptionsResponse, "data.success", false)) {
                return _get(productOptionsResponse, "data.data", [])
            }
        } catch (e) {

        }
        return [];
    };

    const loadUser = async () => {
        setIsSubmitting(true)
        try {
            const response = await axios.get(`/user/edit/${id}`);
            if(_get(response, "data.success", false)) {
                let response_item = _get(response, "data.data", {})
                if(response_item) {
                    await form.setValues(_pick(response_item, ["role", "distributor_name", "first_name", "last_name", "products"]));
                }
            }
        } catch (e) {

        }
        setIsSubmitting(false)
    };

    useEffect(() => {
        getConfig();
        loadUser();
    }, [])

    return (
        <>
            <Helmet>
                <title>{config.title} - Update User</title>
            </Helmet>
            <ToastContainer/>
            <div className="job-create mt-2">
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Create User</Card.Title>
                                <Card.Text>
                                    <Link to="/users" className={"btn btn-sm btn-primary float-end"}>List Users</Link>
                                </Card.Text>
                                <Card.Body>
                                    <Form onSubmit={form.handleSubmit} encType={"multipart/form-data"}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        First Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="First Name"
                                                        name="first_name"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.first_name}
                                                        isInvalid={!!form.errors.first_name}
                                                    />
                                                    {form.touched.first_name && form.errors.first_name ? (
                                                        <Form.Control.Feedback>{form.errors.first_name}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Last Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Last Name"
                                                        name="last_name"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.last_name}
                                                        isInvalid={!!form.errors.last_name}
                                                    />
                                                    {form.touched.last_name && form.errors.last_name ? (
                                                        <Form.Control.Feedback>{form.errors.last_name}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicRole">
                                                    <Form.Label className="text-center">
                                                        Select Role
                                                    </Form.Label>
                                                    <Form.Select
                                                        placeholder="Select Role"
                                                        name="role"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.role}
                                                    >
                                                        <option>Select Role</option>
                                                        {roles.map((opt, index) => (
                                                            <option key={opt} value={opt}>{opt}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {form.touched.role && form.errors.role ? (
                                                        <Form.Control.Feedback type="invalid">{form.errors.role}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Distributor Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Distributor Name"
                                                        name="distributor_name"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.distributor_name}
                                                        isInvalid={!!form.errors.distributor_name}
                                                    />
                                                    {form.touched.distributor_name && form.errors.distributor_name ? (
                                                        <Form.Control.Feedback>{form.errors.distributor_name}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formProducts">
                                                    <Form.Label className="text-center">
                                                        Assign Products
                                                    </Form.Label>
                                                    <AsyncSelect
                                                        name="products"
                                                        cacheOptions
                                                        defaultOptions
                                                        isClearable={true}
                                                        isMulti={true}
                                                        //value={form.values.products}
                                                        // onBlur={() => form.setFieldTouched("products")}
                                                        // onChange={(values) => {
                                                        //     form.setFieldValue("products", values.map((records, index) => records.value))
                                                        //     console.log(form.values)
                                                        // }}
                                                        loadOptions={(inputValue: string) => filterProductOptions(inputValue)}
                                                    />
                                                    {/*{form.touched.products && form.errors.products ? (*/}
                                                    {/*    <Form.Control.Feedback>{form.errors.products}</Form.Control.Feedback>*/}
                                                    {/*) : null}*/}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <div className="d-grid">
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? "Please wait..." : "Submit"}
                                            </Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default UserEdit;