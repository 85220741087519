import {useEffect, useState} from "react";
import {useAuthenticatedAxios} from "../../hooks/useAxios";
import {get as _get} from "lodash"
import {Button, ButtonGroup, Card, Col, Dropdown, DropdownButton, Row, Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";


const JobList = () => {
    const [jobs, setJobs] = useState<Record<string, any>[]>([]);
    const [loading, setLoading] = useState(false);
    const [next_token, setNextToken] = useState(null);
    const axios = useAuthenticatedAxios()

    useEffect(() =>  {
        loadMore()
    }, [])

    const loadMore = async () => {
        setLoading(true)
        try {
            let params: Record<string, any> = {}
            if(next_token) {
                params["next_token"] = next_token
            }
            const response = await axios.get("/jobs", {params: params});
            if(_get(response, "data.success", false)) {
                let response_items = _get(response, "data.data.items", [])
                setJobs(response_items)
                setNextToken(_get(response, "data.data.next_token", null))
            }
        } catch (e) {

        }
        setLoading(false)
    };

    function axiosDownloadFile(fileName: string) {
        return axios({
            url: `/job/sample/${fileName}`,
            method: 'GET',
            responseType: 'blob',
        })
            .then(response => {
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement('a');

                anchorElement.href = href;
                anchorElement.download = fileName;

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            })
            .catch(error => {
                console.log('error: ', error);
            });
    }


    return (
        <div className="products-listing mt-2">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Registered Jobs</Card.Title>
                            <Card.Text>
                                All the registered jobs are processed by cron every 4 hours.
                                <Link to="/jobs/create" className={"btn btn-sm btn-success float-end"}>Add Job</Link>
                                <DropdownButton
                                    as={ButtonGroup}
                                    variant="primary"
                                    title="Download"
                                    size="sm"
                                    className={"float-end"}
                                >
                                    <Dropdown.Item onClick={() => axiosDownloadFile("import_serials.csv")} >Mac Serials CSV</Dropdown.Item>
                                    <Dropdown.Item onClick={() => axiosDownloadFile("import_distributors.csv")} >Distributors CSV</Dropdown.Item>
                                </DropdownButton>
                            </Card.Text>
                            <Card.Body>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>File</th>
                                        <th>Status</th>
                                        <th>Message</th>
                                        <th>Created</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {jobs.map((job, index) => (
                                        <tr key={index}>
                                            <td>{job.file_name}<br/><span className={"text-muted"}>{job.job_type}</span></td>
                                            <td><strong>{job.status}</strong></td>
                                            <td>{job.message}</td>
                                            <td>{job.created}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                                {next_token && (
                                    <div className="d-flex justify-content-center mt-2">
                                        <Button onClick={loadMore} disabled={loading}>
                                            {loading && (
                                                <Spinner animation="border" role="status"  variant="info" size="sm">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            )} Load More..
                                        </Button>
                                    </div>
                                )}
                            </Card.Body>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default JobList;

