import config from "../../config";
import {Helmet} from "react-helmet-async";
import React from "react";
import JobListPartial from "../../components/JobList";

const JobList = () => {
    return (
        <>
            <Helmet>
                <title>{config.title} - List Jobs</title>
            </Helmet>
            <JobListPartial />
        </>
    );
};

export default JobList;