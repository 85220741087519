import config from "../../config";
import {Helmet} from "react-helmet-async";
import React, {useEffect, useRef, useState} from "react";
import {useAuthenticatedAxios} from "../../hooks/useAxios";
import {useFormik} from "formik";
import {get as _get} from "lodash";
import * as Yup from "yup";
import {Button, Card, Col, Form, Row, Spinner, Table, Toast, ToastContainer} from "react-bootstrap";
import {Link} from "react-router-dom";

const DialSchema = Yup.object().shape({
    name: Yup.string().required('Dial Name is required'),
    hardware: Yup.string().required('Dial Hardware is required'),
    dial_num: Yup.number().required('Dial Num is required'),
});

const DialCreate = () => {
    const ref = useRef<HTMLInputElement>(null);
    const bin_ref = useRef<HTMLInputElement>(null);
    const [hardware, setHardware] = useState<Array<Record<string, any>>>([]);
    const axios = useAuthenticatedAxios()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [show, setShow] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    useEffect(() => {
        const getConfig = async () => {
            const jobsResponse = await axios.get("/dials/config");
            if(_get(jobsResponse, "data.success", false)) {
                let hardwareResponse = _get(jobsResponse, "data.data.hardware", [])
                setHardware(hardwareResponse)
            }
        };
        getConfig();
    }, [])

    const form = useFormik({
        initialValues: {
            name: "",
            hardware: "",
            dial_num: "",
            bin_file: "",
            img_file: "",
        },
        validationSchema: DialSchema,
        onSubmit: async(values, formikHelpers) => {
            setIsSubmitting(true);
            try {
                const configHeader = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                };
                const formData = new FormData();
                formData.append("name", values.name);
                formData.append("hardware", values.hardware);
                formData.append("dial_num", values.dial_num);
                formData.append("img_file", values.img_file);
                formData.append("bin_file", values.bin_file);
                const response = await axios.post(config.apiEndpoints.createDial, formData, configHeader);
                if(response.data.success) {
                    formikHelpers.resetForm();
                    // @ts-ignore
                    ref.current.value = ""
                    // @ts-ignore
                    bin_ref.current.value = ""
                    setToastMessage(response.data.message);
                    setShow(true);
                }
            } catch (e) {
                setToastMessage("Error Occurred");
                setShow(true);
            }
            setIsSubmitting(false);
        },
    });
    return (
        <>
            <Helmet>
                <title>{config.title} - Upload Dial</title>
            </Helmet>
            <ToastContainer position="top-end" className="position-absolute mt-4">
                <Toast bg={"dark"} onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Body className={"text-white"}>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
            <div className="dial-create mt-2">
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Create Job</Card.Title>
                                <Card.Text>
                                    <Link to="/dials" className={"btn btn-sm btn-primary float-end"}>List Dials</Link>
                                </Card.Text>
                                <Card.Body>
                                    <Form onSubmit={form.handleSubmit} encType={"multipart/form-data"}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter name"
                                                        name="name"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.name}
                                                        isInvalid={!!form.errors.name}
                                                    />
                                                    {form.touched.name && form.errors.name ? (
                                                        <Form.Control.Feedback>{form.errors.name}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Id
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter name"
                                                        name="dial_num"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.dial_num}
                                                        isInvalid={!!form.errors.dial_num}
                                                    />
                                                    {form.touched.dial_num && form.errors.dial_num ? (
                                                        <Form.Control.Feedback>{form.errors.dial_num}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicJobType">
                                                    <Form.Label className="text-center">
                                                        Select Hardware
                                                    </Form.Label>
                                                    <Form.Select
                                                        placeholder="Select hardware"
                                                        name="hardware"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.hardware}
                                                    >
                                                        <option>Select hardware</option>
                                                        {hardware.map((opt, index) => (
                                                            <option key={opt.value} value={opt.value}>{opt.label}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {form.touched.hardware && form.errors.hardware ? (
                                                        <Form.Control.Feedback type="invalid">{form.errors.hardware}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Image File
                                                    </Form.Label>
                                                    <Form.Control
                                                        ref={ref}
                                                        type="file"
                                                        name="img_file"
                                                        onChange={(event) => {
                                                            const files = (event.currentTarget as HTMLInputElement).files;
                                                            if(files) {
                                                                form.setFieldValue("img_file", files[0]);
                                                            }
                                                        }}
                                                    />
                                                    {form.touched.img_file && form.errors.img_file ? (
                                                        <Form.Control.Feedback type="invalid">{form.errors.img_file}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Bin File
                                                    </Form.Label>
                                                    <Form.Control
                                                        ref={bin_ref}
                                                        type="file"
                                                        name="bin_file"
                                                        onChange={(event) => {
                                                            const files = (event.currentTarget as HTMLInputElement).files;
                                                            if(files) {
                                                                form.setFieldValue("bin_file", files[0]);
                                                            }
                                                        }}
                                                    />
                                                    {form.touched.bin_file && form.errors.bin_file ? (
                                                        <Form.Control.Feedback type="invalid">{form.errors.bin_file}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="d-grid">
                                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                        {isSubmitting ? "Please wait..." : "Submit"}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default DialCreate;