import {useEffect, useState} from "react";
import {useAuthenticatedAxios} from "../../hooks/useAxios";
import {get as _get, join as _join} from "lodash"
import {Button, Card, Col, Row, Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";


const UserList = () => {
    const [users, setUsers] = useState<Record<string, any>[]>([]);
    const [loading, setLoading] = useState(false);
    const [next_token, setNextToken] = useState(null);
    const axios = useAuthenticatedAxios()

    useEffect(() =>  {
        loadMore()
    }, []);

    const loadMore = async () => {
        setLoading(true)
        try {
            let params: Record<string, any> = {}
            if(next_token) {
                params["next_token"] = next_token
            }
            const response = await axios.get("/users", {params: params});
            if(_get(response, "data.success", false)) {
                let response_items = _get(response, "data.data.items", [])
                setUsers(response_items)
                setNextToken(_get(response, "data.data.next_token", null))
            }
        } catch (e) {

        }
        setLoading(false)
    };

    const getName = (user: Record<string, any>) => {
        const names = []
        if(user.first_name) {
            names.push(user.first_name)
        }
        if(user.last_name) {
            names.push(user.last_name)
        }
        return _join(names, " ")
    }

    return (
        <div className="users-listing mt-2">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Registered Users</Card.Title>
                            <Card.Text>
                                With supporting text below as a natural lead-in to additional content.
                                <Link to="/users/create" className={"btn btn-sm btn-primary float-end"}>Add User</Link>
                            </Card.Text>
                            <Card.Body>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email/Phone</th>
                                        <th>Role</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user.distributor_name? user.distributor_name:getName(user)}</td>
                                            <td>
                                                {user.email? user.email:user.phone}
                                            </td>
                                            <td className={"text-warning"}>{user.role}</td>
                                            <td>
                                                <span className={`badge bg-${user.is_active? "success":"warning"}`}>{user.is_active? "Active":"InActive"}</span>
                                                <span className={`badge bg-${user.is_verified? "success":"warning"}`}>{user.is_verified? "Verified":"Pending"}</span>
                                            </td>
                                            <td><Link to={`/users/edit/${user.id}`}>EDIT</Link></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                                {next_token && (
                                    <div className="d-flex justify-content-center mt-2">
                                        <Button onClick={loadMore} disabled={loading}>
                                            {loading && (
                                                <Spinner animation="border" role="status"  variant="info" size="sm">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            )} Load More..
                                        </Button>
                                    </div>
                                )}
                            </Card.Body>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </div>
    )
};

export default UserList;

