import config from "../../config";
import {Helmet} from "react-helmet-async";
import React, {useState, KeyboardEventHandler} from "react";
import {useAuthenticatedAxios} from "../../hooks/useAxios";
import {useFormik} from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import CreatableSelect from 'react-select/creatable';

const ProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    sku: Yup.string().required('SKU is required'),
    code: Yup.string().required('Identifier Code is required'),
    image_url: Yup.string().required('Image URL is required'),
});

const components = {
    DropdownIndicator: null,
};

const ProductCreate = () => {
    const axios = useAuthenticatedAxios()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const form = useFormik({
        initialValues: {
            name: "",
            sku: "",
            code: "",
            identifiers: [],
            image_url: "https://cdn.shopify.com/s/files/1/0395/8485/8275/products/processed-bf510f8a-9cfa-440c-853a-52fa18a259da_fuNrjgO3_400x.jpg?v=1645001017"
        },
        validationSchema: ProductSchema,
        onSubmit: async(values, formikHelpers) => {
            setIsSubmitting(true);
            try {
                const response = await axios.post("/product/add", values);
                if(response.data.success) {
                    formikHelpers.resetForm();
                    toast(response.data.message);
                }
            } catch (e) {
                toast("Error Occurred");
            }
            setIsSubmitting(false);
        },
    });



    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                form.setFieldValue("identifiers", [...form.values.identifiers, ...[inputValue]]);
                setInputValue('');
                event.preventDefault();
        }
    };

    return (
        <>
            <Helmet>
                <title>{config.title} - Create Product</title>
            </Helmet>
            <ToastContainer/>
            <div className="job-create mt-2">
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Create Product</Card.Title>
                                <Card.Text>
                                    <Link to="/products" className={"btn btn-sm btn-primary float-end"}>List Products</Link>
                                </Card.Text>
                                <Card.Body>
                                    <Form onSubmit={form.handleSubmit}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter name"
                                                        name="name"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.name}
                                                        isInvalid={!!form.errors.name}
                                                    />
                                                    {form.touched.name && form.errors.name ? (
                                                        <Form.Control.Feedback>{form.errors.name}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        SKU
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter sku"
                                                        name="sku"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.sku}
                                                        isInvalid={!!form.errors.sku}
                                                    />
                                                    {form.touched.sku && form.errors.sku ? (
                                                        <Form.Control.Feedback>{form.errors.sku}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Primary Identifier Code
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Identifier"
                                                        name="code"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.code}
                                                        isInvalid={!!form.errors.code}
                                                    />
                                                    {form.touched.code && form.errors.code ? (
                                                        <Form.Control.Feedback>{form.errors.code}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Other Identifiers
                                                    </Form.Label>
                                                    <CreatableSelect
                                                        components={components}
                                                        inputValue={inputValue}
                                                        isClearable
                                                        isMulti
                                                        menuIsOpen={false}
                                                        onChange={(newValue) => form.setFieldValue("identifiers", newValue.map(t => t.value))}
                                                        onInputChange={(newValue) => setInputValue(newValue)}
                                                        onKeyDown={handleKeyDown}
                                                        placeholder="Type something and press enter..."
                                                        value={form.values.identifiers.map(t => ({value: t, label: t}))}
                                                    />
                                                    {form.touched.identifiers && form.errors.identifiers ? (
                                                        <Form.Control.Feedback>{form.errors.identifiers}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {/*<Form.Group className="mb-3" controlId="formBasicEmail">*/}
                                                {/*    <Form.Label className="text-center">*/}
                                                {/*        Image URL*/}
                                                {/*    </Form.Label>*/}
                                                {/*    <Form.Control*/}
                                                {/*        type="text"*/}
                                                {/*        placeholder="Image"*/}
                                                {/*        name="image_url"*/}
                                                {/*        onChange={form.handleChange}*/}
                                                {/*        onBlur={form.handleBlur}*/}
                                                {/*        value={form.values.image_url}*/}
                                                {/*        isInvalid={!!form.errors.image_url}*/}
                                                {/*    />*/}
                                                {/*    {form.touched.image_url && form.errors.image_url ? (*/}
                                                {/*        <Form.Control.Feedback>{form.errors.image_url}</Form.Control.Feedback>*/}
                                                {/*    ) : null}*/}
                                                {/*</Form.Group>*/}
                                            </Col>
                                        </Row>

                                        <div className="d-grid">
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? "Please wait..." : "Submit"}
                                            </Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ProductCreate;