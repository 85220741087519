import config from "../../config";
import {Helmet} from "react-helmet-async";
import React, {useEffect, useRef, useState} from "react";
import {useAuthenticatedAxios} from "../../hooks/useAxios";
import {useFormik} from "formik";
import {get as _get} from "lodash";
import * as Yup from "yup";
import {Button, Card, Col, Form, Row, Spinner, Table, Toast, ToastContainer} from "react-bootstrap";
import {Link} from "react-router-dom";

const UserSchema = Yup.object().shape({
    first_name: Yup.string().required('Firstname is required'),
    last_name: Yup.string().required('Lastname is required'),
    role: Yup.string().required('Role is required'),
    email: Yup.string().required('Email is required').email(),
});

const UserCreate = () => {
    const axios = useAuthenticatedAxios()
    const [roles, setRoles] = useState(["Admin", "Super Distributor", "Master Distributor"]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [show, setShow] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    useEffect(() => {
        const getConfig = async () => {
            const configResponse = await axios.get("/users/config");
            if(_get(configResponse, "data.success", false)) {
                let roles = _get(configResponse, "data.data.roles", [])
                setRoles(roles)
            }
        };
        getConfig();
    }, [])

    const form = useFormik({
        initialValues: {
            role: "Admin",
            email: "",
            distributor_name: "",
            first_name: "",
            last_name: "",
            products: []
            // is_active: true,
            // is_verified: true
        },
        validationSchema: UserSchema,
        onSubmit: async(values, formikHelpers) => {
            setIsSubmitting(true);
            try {
                const response = await axios.post(config.apiEndpoints.createUser, values);
                if(response.data.success) {
                    formikHelpers.resetForm();
                    setToastMessage(response.data.message);
                    setShow(true);
                }
            } catch (e) {
                setToastMessage("Error Occurred");
                setShow(true);
            }
            setIsSubmitting(false);
        },
    });
    return (
        <>
            <Helmet>
                <title>{config.title} - Create User</title>
            </Helmet>
            <ToastContainer position="top-end" className="position-absolute mt-4">
                <Toast bg={"dark"} onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Body className={"text-white"}>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
            <div className="job-create mt-2">
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Create User</Card.Title>
                                <Card.Text>
                                    <Link to="/users" className={"btn btn-sm btn-primary float-end"}>List Users</Link>
                                </Card.Text>
                                <Card.Body>
                                    <Form onSubmit={form.handleSubmit} encType={"multipart/form-data"}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicJobType">
                                                    <Form.Label className="text-center">
                                                        Select Role
                                                    </Form.Label>
                                                    <Form.Select
                                                        placeholder="Select Role"
                                                        name="role"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.role}
                                                    >
                                                        <option>Select Role</option>
                                                        {roles.map((opt, index) => (
                                                            <option key={opt} value={opt}>{opt}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {form.touched.role && form.errors.role ? (
                                                        <Form.Control.Feedback type="invalid">{form.errors.role}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Email address
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter email"
                                                        name="email"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.email}
                                                        isInvalid={!!form.errors.email}
                                                    />
                                                    {form.touched.email && form.errors.email ? (
                                                        <Form.Control.Feedback>{form.errors.email}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        First Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="First Name"
                                                        name="first_name"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.first_name}
                                                        isInvalid={!!form.errors.first_name}
                                                    />
                                                    {form.touched.first_name && form.errors.first_name ? (
                                                        <Form.Control.Feedback>{form.errors.first_name}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Last Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Last Name"
                                                        name="last_name"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.last_name}
                                                        isInvalid={!!form.errors.last_name}
                                                    />
                                                    {form.touched.last_name && form.errors.last_name ? (
                                                        <Form.Control.Feedback>{form.errors.last_name}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Distributor Name
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Distributor Name"
                                                        name="distributor_name"
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.distributor_name}
                                                        isInvalid={!!form.errors.distributor_name}
                                                    />
                                                    {form.touched.distributor_name && form.errors.distributor_name ? (
                                                        <Form.Control.Feedback>{form.errors.distributor_name}</Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col></Col>
                                        </Row>

                                        <div className="d-grid">
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? "Please wait..." : "Submit"}
                                            </Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default UserCreate;