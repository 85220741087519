import {useEffect, useState} from "react";
import {useAuthenticatedAxios} from "../../hooks/useAxios";
import {get as _get} from "lodash"
import {Button, ButtonGroup, Card, Col, Dropdown, DropdownButton, Row, Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";


const JobList = () => {
    const [dials, setDials] = useState<Record<string, any>[]>([]);
    const [loading, setLoading] = useState(false);
    const [next_token, setNextToken] = useState(null);
    const axios = useAuthenticatedAxios()

    useEffect(() =>  {
        loadMore()
    }, [])

    const loadMore = async () => {
        setLoading(true)
        try {
            let params: Record<string, any> = {}
            if(next_token) {
                params["next_token"] = next_token
            }
            const response = await axios.get("/dials", {params: params});
            if(_get(response, "data.success", false)) {
                let response_items = _get(response, "data.data.items", [])
                if(response_items.length) {
                    setDials([...dials, ...response_items])
                }
                setNextToken(_get(response, "data.data.next_token", null))
            }
        } catch (e) {

        }
        setLoading(false)
    };

    function axiosDownloadFile(fileName: string) {
        return axios({
            url: `${fileName}`,
            method: 'GET',
            responseType: 'blob',
        })
            .then(response => {
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement('a');

                anchorElement.href = href;
                anchorElement.download = fileName;

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            })
            .catch(error => {
                console.log('error: ', error);
            });
    }


    return (
        <div className="products-listing mt-2">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Registered Dials</Card.Title>
                            <Card.Text>
                                <Link to="/dials/create" className={"btn btn-sm btn-success float-end"}>Add Dial</Link>
                            </Card.Text>
                            <Card.Body>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Files</th>
                                        <th>Created</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {dials.map((dial, index) => (
                                        <tr key={index}>
                                            <td>{dial.name}<br/><span className={"text-muted"}>{dial.hardware}</span></td>
                                            <td><strong>{dial.is_active? "Active":"InActive"}</strong></td>
                                            <td>
                                                <a href={dial.binUrl} target="_blank">Bin File</a><br />
                                                <a href={dial.imgUrl} target="_blank">Image File</a>
                                            </td>
                                            <td>{dial.created}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                                {next_token && (
                                    <div className="d-flex justify-content-center mt-2">
                                        <Button onClick={loadMore} disabled={loading}>
                                            {loading && (
                                                <Spinner animation="border" role="status"  variant="info" size="sm">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            )} Load More..
                                        </Button>
                                    </div>
                                )}
                            </Card.Body>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default JobList;

