import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import useAuth from "../../hooks/useAuth";

const Profile = () => {
    const {user} = useAuth();
  return (
      <div className="product-device-count mt-2">
          <Card>
              <Card.Body>
                  <Card.Title>{user?.name}</Card.Title>
                  <Card.Body>
                      <Row>
                          <Col>

                          </Col>
                      </Row>
                  </Card.Body>
              </Card.Body>
          </Card>
      </div>
  )
}

export default Profile;
