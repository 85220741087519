import {useEffect, useState} from "react";
import {useAuthenticatedAxios} from "../../hooks/useAxios";
import {get as _get} from "lodash"
import {Button, Card, Col, Row, Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";


const ProductList = () => {
    const [products, setProducts] = useState<Record<string, any>[]>([]);
    const [loading, setLoading] = useState(false);
    const [next_token, setNextToken] = useState(null);
    const axios = useAuthenticatedAxios()

    useEffect(() =>  {
        loadMore()
    }, [])

    const loadMore = async () => {
        setLoading(true)
        try {
            let params: Record<string, any> = {}
            if(next_token) {
                params["next_token"] = next_token
            }
            const response = await axios.get("/products", {params: params});
            if(_get(response, "data.success", false)) {
                let response_items = _get(response, "data.data.items", [])
                setProducts(response_items)
                setNextToken(_get(response, "data.data.next_token", null))
            }
        } catch (e) {

        }
        setLoading(false)
    };

    return (
        <div className="products-listing mt-2">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Registered Products</Card.Title>
                            <Card.Text>
                                With supporting text below as a natural lead-in to additional content.
                                <Link to="/products/create" className={"btn btn-sm btn-primary float-end"}>Add Product</Link>
                            </Card.Text>
                            <Card.Body>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>SKU</th>
                                        <th>Device Count</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {products.map((product, index) => (
                                        <tr key={index}>
                                            <td>{product.image_url && <img width={100} src={product.image_url} />}</td>
                                            <td>
                                                {product.name}<br/>
                                                <span className={"text-muted"}>{product.identifiers.join(", ")}</span>
                                            </td>
                                            <td><strong>{product.sku}</strong></td>
                                            <td className={"text-warning"}>{product.device_count}</td>
                                            <td><Link to={`/products/edit/${product.code}`}>EDIT</Link></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                                {next_token && (
                                    <div className="d-flex justify-content-center mt-2">
                                        <Button onClick={loadMore} disabled={loading}>
                                            {loading && (
                                                <Spinner animation="border" role="status"  variant="info" size="sm">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            )} Load More..
                                        </Button>
                                    </div>
                                )}
                            </Card.Body>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default ProductList;

