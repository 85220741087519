import React from "react";
import { Helmet } from 'react-helmet-async';
import DeviceCount from "../components/DeviceCount";
import DeviceList from "../components/DeviceList";
import config from "../config";

const Home = () => {
  return (
      <>
          <Helmet>
              <title>{config.title} - Products</title>
              <meta property="og:url" content="pets.abc" />
              <meta property="og:site_name" content="Pets - Products" />
              <meta property="og:locale" content="en_US" />
              <meta property="og:type" content="article" />
              <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
          </Helmet>
          <DeviceCount></DeviceCount>
          <DeviceList></DeviceList>
      </>
  )
}

export default Home;
