import config from "../../config";
import {Helmet} from "react-helmet-async";
import React from "react";
import ProductListPartial from "../../components/ProductList";

const ProductList = () => {
    return (
        <>
            <Helmet>
                <title>{config.title} - List Products</title>
            </Helmet>
            <ProductListPartial />
        </>
    );
};

export default ProductList;