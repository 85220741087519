import axios from "axios";
import useAuth from "./useAuth";
import config from "../config";

const useAuthenticatedAxios = () => {
    const {token, refresh_token, refreshAccessToken, logout} = useAuth();
    const instance = axios.create({
        baseURL: config.apiBase,
        headers: {
            "Content-Type": "application/json",
        },
    });

    instance.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry && refresh_token) {
                    originalConfig._retry = true;
                    try {
                        const access_token = await refreshAccessToken();
                        if(access_token){
                            originalConfig.headers["Authorization"] = `Bearer ${access_token}`;
                            return instance(originalConfig);
                        } else {
                            logout()
                        }
                    } catch (_error) {
                        logout()
                    }
                }

                if (err.response.status === 403 && err.response.data) {
                    return Promise.reject(err.response.data);
                }
            }

            return Promise.reject(err);
        }
    );
    return instance
}

const useAxios = () => {
    const instance = axios.create({
        baseURL: config.apiBase,
        headers: {
            "Content-Type": "application/json",
        },
    });

    instance.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return instance
}

export {useAxios, useAuthenticatedAxios};
