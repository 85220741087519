import {useEffect, useState} from "react";
import {get as _get, join as _join} from "lodash"
import {Button, Card, Col, Row, Spinner, Table} from "react-bootstrap";
import "./index.scss";
import Filterable from "../Filterable";
import useFilter, {FilterableFieldsGroupInterface, SortableFieldsInterface} from "../../hooks/useFilter";
import useAuth, {Role} from "../../hooks/useAuth";


const DeviceList = () => {
    const {hasRole} = useAuth();
    const [showFilterable, setShowFilterable] = useState(false);

    const filterableFields: Array<FilterableFieldsGroupInterface> = [
        {
            title: "Customer",
            fields: [
                {
                    label: "Email",
                    value: "email",
                    type: "string"
                },
            ]
        },
        {
            title: "Device",
            fields: [
                {
                    label: "Registered On",
                    value: "registration_date",
                    type: "datetime"
                },
                {
                    label: "Mac Address",
                    value: "device_mac",
                    type: "string"
                },
            ]
        },
        {
            title: "Distributors",
            fields: [
                {
                    label: "Distributor Name",
                    value: "super_distributor",
                    type: "string"
                },
            ]
        },
        {
            title: "Product",
            fields: [
                {
                    label: "Name",
                    value: "product_name",
                    type: "string"
                },
                {
                    label: "SKU",
                    value: "product_sku",
                    type: "string"
                },
            ]
        }
    ];

    const sortableFields: Array<SortableFieldsInterface> = [
        {
            label: "Name",
            value: "name"
        },
        {
            label: "Email",
            value: "email"
        }
    ];

    const {
        loading,
        records,
        meta,
        selectedFilters,
        sortColumn,
        removeSortColumn,
        resetSorting,
        applyFilters,
        exportRecords,
        fetchRecords,
        addFilter,
        removeFilter,
        resetFilter,
        onFilterColumnSelectHandler,
        onFilterOperatorSelectHandler,
        onFilterValueOneChangeHandler,
        onFilterValueTwoChangeHandler
    } = useFilter({
        endpoint: "/devices",
        exportIdentifier: "Registered Devices",
        exportEndpoint: "/export/create",
        queryParams: []
    })

    useEffect(() =>  {
        fetchRecords()
    }, []);

    const getName = (device: Record<string, any>) => {
        const names = []
        if(device.first_name) {
            names.push(device.first_name)
        }
        if(device.last_name) {
            names.push(device.last_name)
        }
        return _join(names, " ")
    }

    const loadMore = () => {
        const next_token = _get(meta, "next_token", null);
        if(next_token) {
            fetchRecords();
        }
    }

    const toggleFilters = () => {
        setShowFilterable(!showFilterable)
    };

    return (
        <div className="product-device-list mt-2">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Registered Devices</Card.Title>
                            <div className="page-actions">
                                <Button className="float-end btn btn-secondary btn-sm" onClick={toggleFilters}>Filters</Button>
                                <div className="clearfix"></div>
                            </div>
                            <Filterable
                                loading={loading}
                                canExport={hasRole(Role.ROLE_SUPER_ADMIN)}
                                showFilterable={showFilterable}
                                selectedFilters={selectedFilters}
                                sortColumn={sortColumn}
                                removeSortColumn={removeSortColumn}
                                resetSorting={resetSorting}
                                filterableFields={filterableFields}
                                sortableFields={sortableFields}
                                queryParams={[]}
                                exportRecords={exportRecords}
                                applyFilters={applyFilters}
                                addFilter={addFilter}
                                removeFilter={removeFilter}
                                resetFilter={resetFilter}
                                onFilterColumnSelectHandler={onFilterColumnSelectHandler}
                                onFilterOperatorSelectHandler={onFilterOperatorSelectHandler}
                                onFilterValueOneChangeHandler={onFilterValueOneChangeHandler}
                                onFilterValueTwoChangeHandler={onFilterValueTwoChangeHandler}
                             ></Filterable>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Name.</th>
                                    <th>Gender.</th>
                                    <th>Email/Phone</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Product</th>
                                    <th>Registered On</th>
                                    <th>Distributor Name</th>
                                    <th>MD Name</th>
                                </tr>
                                </thead>
                                <tbody>
                                {records.map((device, index) => (
                                    <tr key={index}>
                                        <td>{getName(device)}</td>
                                        <td>{device.gender}</td>
                                        <td>{device.email? device.email:device.phone}</td>
                                        <td>{(device.city && device.city !== 'Jaipur')? device.city:"-"}</td>
                                        <td>{(device.state && device.state !== 'Raj')? device.state:"-"}</td>
                                        <td className="d-flex">
                                            <span>{device.product_name}</span>[<span className="text-muted">{device.product_sku}</span>]
                                        </td>
                                        <td>{device.registration_date}</td>
                                        <td>{device.super_distributor? device.super_distributor:"-"}</td>
                                        <td>{device.master_distributor? device.master_distributor:"-"}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            {("next_token" in meta && meta["next_token"])  && (
                                <div className="d-flex mt-2">
                                    <Button onClick={loadMore} disabled={loading}>
                                        {loading && (
                                            <Spinner animation="border" role="status"  variant="info" size="sm">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        )} Load More..
                                    </Button>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default DeviceList;

