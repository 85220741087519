import config from "../../config";
import {Helmet} from "react-helmet-async";
import React, {useEffect, useRef, useState} from "react";
import {useAuthenticatedAxios} from "../../hooks/useAxios";
import {useFormik} from "formik";
import {get as _get} from "lodash";
import * as Yup from "yup";
import {Button, Card, Col, Form, Row, Spinner, Table, Toast, ToastContainer} from "react-bootstrap";
import {Link} from "react-router-dom";

const JobSchema = Yup.object().shape({
    job_type: Yup.string().required('Job type is required'),
});

const JobCreate = () => {
    const ref = useRef<HTMLInputElement>(null);
    const [job_types, setJobTypes] = useState([]);
    const axios = useAuthenticatedAxios()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [show, setShow] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    useEffect(() => {
        const getConfig = async () => {
            const jobsResponse = await axios.get("/jobs/config");
            if(_get(jobsResponse, "data.success", false)) {
                let job_types = _get(jobsResponse, "data.data.job_types", [])
                setJobTypes(job_types)
            }
        };
        getConfig();
    }, [])

    const form = useFormik({
        initialValues: {
            job_type: "",
            file: "",
        },
        validationSchema: JobSchema,
        onSubmit: async(values, formikHelpers) => {
            setIsSubmitting(true);
            try {
                const configHeader = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                };
                const formData = new FormData();
                formData.append("job_type", values.job_type);
                formData.append("file", values.file);
                const response = await axios.post(config.apiEndpoints.createJob, formData, configHeader);
                if(response.data.success) {
                    formikHelpers.resetForm();
                    // @ts-ignore
                    ref.current.value = ""
                    setToastMessage(response.data.message);
                    setShow(true);
                }
            } catch (e) {
                setToastMessage("Error Occurred");
                setShow(true);
            }
            setIsSubmitting(false);
        },
    });
    return (
        <>
            <Helmet>
                <title>{config.title} - Create Job</title>
            </Helmet>
            <ToastContainer position="top-end" className="position-absolute mt-4">
                <Toast bg={"dark"} onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Body className={"text-white"}>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
            <div className="job-create mt-2">
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Create Job</Card.Title>
                                <Card.Text>
                                    <Link to="/jobs" className={"btn btn-sm btn-primary float-end"}>List Jobs</Link>
                                </Card.Text>
                                <Card.Body>
                                    <Form onSubmit={form.handleSubmit} encType={"multipart/form-data"}>
                                        <Form.Group className="mb-3" controlId="formBasicJobType">
                                            <Form.Label className="text-center">
                                                Select Job Type
                                            </Form.Label>
                                            <Form.Select
                                                placeholder="Select Job Type"
                                                name="job_type"
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.job_type}
                                            >
                                                <option>Select Job Type</option>
                                                {job_types.map((opt, index) => (
                                                    <option key={opt} value={opt}>{opt}</option>
                                                ))}
                                            </Form.Select>
                                            {form.touched.job_type && form.errors.job_type ? (
                                                <Form.Control.Feedback type="invalid">{form.errors.job_type}</Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label className="text-center">
                                                CSV File
                                            </Form.Label>
                                            <Form.Control
                                                ref={ref}
                                                type="file"
                                                name="file"
                                                onChange={(event) => {
                                                    const files = (event.currentTarget as HTMLInputElement).files;
                                                    if(files) {
                                                        form.setFieldValue("file", files[0]);
                                                    }
                                                }}
                                            />
                                            {form.touched.file && form.errors.file ? (
                                                <Form.Control.Feedback type="invalid">{form.errors.file}</Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>

                                        <div className="d-grid">
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? "Please wait..." : "Submit"}
                                            </Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default JobCreate;