import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {AuthProvider} from "./contexts/AuthContext";
import './App.scss';
import {DefaultLayout} from "./layouts/DefaultLayout";
import Login from "./pages/auth/Login";
import {ProtectedLayout} from "./layouts/ProtectedLayout";
import Home from "./pages/Home";

import Profile from "./pages/account/Profile";
import NotFound from "./pages/NotFound";
import ProductList from "./pages/products/List";
import ProductCreate from "./pages/products/Create";
import UserList from "./pages/users/List";
import UserCreate from "./pages/users/Create";
import UserEdit from "./pages/users/Edit";
import ProductEdit from "./pages/products/Edit";
import JobList from "./pages/jobs/List";
import JobCreate from "./pages/jobs/Create";
import DialList from "./pages/dials/List";
import DialCreate from "./pages/dials/Create";
import ExportList from "./components/ExportList";

function App() {
  useEffect(() => {
    document.body.classList.add(
        "header-fixed",
        "header-tablet-and-mobile-fixed",
        "aside-fixed",
        "aside-secondary-enabled"
    );
  }, []);
  return (
      <Router>
        <AuthProvider>
          <Routes>
            <Route element={<DefaultLayout/>}>
              <Route path="/login" element={<Login/>}></Route>
            </Route>
            <Route element={<ProtectedLayout/>}>
              <Route path="/" element={<Home/>}></Route>
              <Route path="/products">
                <Route path="" element={<ProductList/>}></Route>
                <Route path="create" element={<ProductCreate/>}></Route>
                <Route path="edit/:id" element={<ProductEdit/>}></Route>
              </Route>
              <Route path="/users">
                <Route path="" element={<UserList/>}></Route>
                <Route path="create" element={<UserCreate/>}></Route>
                <Route path="edit/:id" element={<UserEdit/>}></Route>
              </Route>
              <Route path="/jobs">
                <Route path="" element={<JobList/>}></Route>
                <Route path="create" element={<JobCreate/>}></Route>
              </Route>
              <Route path="/dials">
                <Route path="" element={<DialList/>}></Route>
                <Route path="create" element={<DialCreate/>}></Route>
              </Route>
              <Route path="/exports">
                <Route path="" element={<ExportList/>}></Route>
              </Route>
              <Route path="/account">
                <Route path="" element={<Profile/>}></Route>
              </Route>
            </Route>
            <Route path='*' element={<NotFound/>}/>
          </Routes>
        </AuthProvider>
      </Router>
  );
}

export default App;
