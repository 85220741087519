import config from "../../config";
import {Helmet} from "react-helmet-async";
import React from "react";
import DialListPartial from "../../components/DialList";

const DialList = () => {
    return (
        <>
            <Helmet>
                <title>{config.title} - List Dials</title>
            </Helmet>
            <DialListPartial />
        </>
    );
};

export default DialList;